import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { NotificationManager } from "react-notifications";
import { ReactComponent as Failure } from "../../assets/images/Failure.svg";
import { ReactComponent as Ongoing } from "../../assets/images/Ongoing.svg";
import { ReactComponent as Somthingwrong } from "../../assets/images/somthingwrong.svg";
import Loader from "../loader/loader";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class PurchaseOrderStatusModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      paymentStatus: 0,
      paymentError: "",
      isShow: this.props.isShow,
      orderId: this.props.orderId,
    };
    this.closeSuccessModal = this.closeSuccessModal.bind(this);
  }

  componentDidMount() {
    this.validatePayment(this.props.orderId);
  }

  validatePayment = (orderId) => {
    this.setState({ isLoading: 1 });
    if (orderId !== null && orderId != null && orderId !== "") {
      const payload = {
        orderId: orderId,
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
      };
      UserService.getPaymentStatus(payload)
        .then(async (res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0, paymentStatus: 3 });
          } else {
            if (res["status"]) {
              if (res["data"] != null && res["data"].orderStatus !== null) {
                const orderStatus = res["data"].orderStatus;
                const orderError = res["data"].orderError;
                var paymentStatus = 3;
                if (orderStatus === "PAYMENT_COMPLETE") {
                  paymentStatus = 0;
                  NotificationManager.success("Transaction Success", "", 2000);
                }
                if (orderStatus === "PAYMENT_FAIL") {
                  paymentStatus = 1;
                  NotificationManager.error("Transaction Failed", "", 2000);
                }
                if (orderStatus === "PAYMENT_PROCESS") {
                  paymentStatus = 2;
                  NotificationManager.success("Transaction Ongoing", "", 2000);
                }
                this.setState({
                  isLoading: 0,
                  paymentStatus,
                  paymentError: orderError,
                });
              }
            } else {
              NotificationManager.error(res["message"], "", 2000);
              this.setState({ isLoading: 0, paymentStatus: 3 });
            }
          }
        })
        .catch((err) => {
          NotificationManager.error(err, "", 2000);
          this.setState({ isLoading: 0, paymentStatus: 3 });
        });
    } else {
      var paymentStatus = 1;
      NotificationManager.error("Transaction Failed", "", 2000);
      this.setState({
        isLoading: 0,
        paymentStatus,
        paymentError: "",
      });
    }
  };

  closeSuccessModal = (event) => {
    this.setState({ isShow: false });
    this.props.closeSuccessModal(this.state.paymentStatus === 0);
  };

  render() {
    return this.state.isLoading === 1 ? (
      <Loader />
    ) : (
      <Modal
        show={this.state.isShow}
        onHide={this.closeSuccessModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="add-cart-modal1"
      >
        <div id="addtocart-modal">
          <div className="portal-modalclose">
            <h4 className="add-new" onClick={this.closeSuccessModal}>
              Close
            </h4>
            <img
              src={require("../../assets/images/modal-closeicon.png")}
              alt="Close"
              onClick={this.closeSuccessModal}
            ></img>
          </div>
          <div className="addtocart-information">
            {this.state.paymentStatus === 0 ? (
              <img
                src={require("../../assets/images/Congrats.png")}
                className="hand-img"
                alt="Congrats"
              ></img>
            ) : this.state.paymentStatus === 1 ? (
              <Failure className="payment-img" />
            ) : this.state.paymentStatus === 2 ? (
              <Ongoing className="payment-img" />
            ) : (
              <Somthingwrong className="payment-img" />
            )}

            {this.state.paymentStatus === 0 ? (
              <p>
                Congrats! <br></br>
                Your Ticket is Booked.
              </p>
            ) : this.state.paymentStatus === 1 ? (
              <p>
                Failure <br></br>
                Oops! Your transaction was unsuccessful. Please try again or
                <br></br>
                contact our customer support for assistance.
              </p>
            ) : this.state.paymentStatus === 2 ? (
              <p>
                Ongoing <br></br>
                Your transaction is in progress. Please wait while we
                <br></br>
                process your payment. Thank you for your patience.
              </p>
            ) : (
              <p>
                Oops! <br></br>
                Something went wrong couldn't get your payment status.
              </p>
            )}

            {this.state.paymentError != null &&
              this.state.paymentError !== "" && (
                <p id="payment-error-msg">ERROR: {this.state.paymentError}</p>
              )}

            <div className="set-btns-tickets">
              <button className="GET-TICKETS" onClick={this.closeSuccessModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderStatusModel);
