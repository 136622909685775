import { Link } from "react-router-dom";

const StreamIframe = ({ hide, link, orderId, handleClick }) => {
  console.log(link);
  console.log(orderId);
  console.log(hide);
  return (
    <section
      className="main-carousel"
      id="home-streamiframe"
      style={{ position: "relative" }}
    >
      {orderId != null && orderId !== -1 && orderId !== "" ? (
        <iframe
          src={link}
          allowFullScreen
          frameBorder="0"
          className="video-player"
          style={{
            height: "100%",
          }}
          title="live-stream"
        ></iframe>
      ) : (
        <>
          {hide && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: "100",
              }}
            >
              <h3 style={{ textAlign: "center", fontSize: "32px" }}>
                Preview complete! Ready to experience more? Purchase now to
                start streaming.
              </h3>
              <Link to="/checkout-ticket">
                <button
                  className="ordernow-btn"
                  style={{ marginInline: "auto", display: "block" }}
                >
                  Stream Now
                </button>
              </Link>
              {/* <button
                className="ordernow-btn"
                style={{ marginInline: "auto", display: "block" }}
                onClick={handleClick}
              >
                Order Now
              </button> */}
            </div>
          )}
          <iframe
            src={hide ? "" : link}
            allowFullScreen
            frameBorder="0"
            className="video-player"
            style={{
              height: "100%",
            }}
            title="live-stream"
          ></iframe>
        </>
      )}
    </section>
  );
};

export default StreamIframe;
