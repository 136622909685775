import React from "react";
import { connect } from "react-redux";
import { Form, ProgressBar } from "react-bootstrap";
import { bindActionCreators } from "redux";
import validator from "validator";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { selectemployeeData } from "../../store/home/home.selectors";
import Loader from "../../components/loader/loader";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      resetId: null,
      employeeId: null,
      resetKey: false,
      message: "",

      password: "",
      passwordProgress: 0,
      repeatedPassword: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeData?.employeeId != null) {
      this.props.history.push("/");
    } else {
      this.validateResetKey();
    }
  }

  handlePassword = (event) => {
    const pass = event.target.value;
    const hasLength = pass.length >= 8 ? 1 : 0;
    const hasUppercase = /[A-Z]/.test(pass) ? 1 : 0;
    const hasLowercase = /[a-z]/.test(pass) ? 1 : 0;
    const hasNumber = /[0-9]/.test(pass) ? 1 : 0;
    const progress =
      ((hasLength + hasUppercase + hasLowercase + hasNumber) / 4) * 100;
    this.setState({
      password: event.target.value,
      passwordProgress: progress,
    });
  };
  handleRepeatedPassword = (event) => {
    this.setState({ repeatedPassword: event.target.value });
  };

  validateResetKey = () => {
    const resetKey = window.location.pathname.split("/").pop();

    if (resetKey !== "") {
      const payload = {
        resetKey: resetKey,
      };
      this.setState({ isLoading: 1 });
      UserService.validateResetKey(payload)
        .then((res) => {
          if (res.error != null) {
            const errorMessage =
              typeof res.error?.response?.data === "string" ||
              res.error?.response?.data instanceof String
                ? res.error?.response?.data
                : res.error?.response?.data?.message;
            NotificationManager.error(errorMessage, "", 2000);
            this.timeoutHandle = setTimeout(() => {
              this.setState({
                isLoading: 0,
                resetId: null,
                employeeId: null,
                resetKey: false,
                message: errorMessage,
              });
              this.props.history.push("/authentication/my-account");
            }, 3000);
          } else {
            if (res["status"]) {
              const data = res["data"] != null ? res["data"][0] : res["data"];
              if (data != null) {
                const employee = data.employeeList?.find(
                  (emp) => emp.sphereTypeId === AppConfig.API.SPHERE_TYPE_ID
                );
                this.setState({
                  isLoading: 0,
                  resetId: data.resetId,
                  employeeId: employee?.employeeId,
                  resetKey: true,
                  message: res["message"],
                });
              }
            } else {
              NotificationManager.error(res["message"], "", 2000);
              this.timeoutHandle = setTimeout(() => {
                this.setState({
                  isLoading: 0,
                  resetId: null,
                  employeeId: null,
                  resetKey: false,
                  message: res["message"],
                });
                this.props.history.push("/authentication/my-account");
              }, 3000);
            }
          }
        })
        .catch((err) => {
          NotificationManager.error(err, "", 2000);
          this.timeoutHandle = setTimeout(() => {
            this.setState({
              isLoading: 0,
              resetId: null,
              employeeId: null,
              resetKey: false,
              message: err,
            });
            this.props.history.push("/authentication/my-account");
          }, 3000);
        });
      return;
    }
    NotificationManager.error("ResetKey is not valid.", "", 2000);
    this.timeoutHandle = setTimeout(() => {
      this.setState({
        resetId: null,
        employeeId: null,
        resetKey: false,
        message: "ResetKey is not valid.",
      });
      this.props.history.push("/authentication/my-account");
    }, 3000);
  };

  saveResetPassword = () => {
    if (this.state.resetId != null && this.state.employeeId != null) {
      if (this.state.password === "") {
        NotificationManager.warning("Please Enter Password.", "", 2000);
      } else if (!validator.isStrongPassword(this.state.password)) {
        NotificationManager.warning(
          "Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.",
          "",
          2000
        );
      } else if (this.state.repeatedPassword === "") {
        NotificationManager.warning("Please Enter Confirm Password.", "", 2000);
      } else if (this.state.repeatedPassword !== this.state.password) {
        NotificationManager.warning("Password's do not match.", "", 2000);
      } else {
        const payload = {
          password: this.state.password,
          employeeIds: [this.state.employeeId],
          resetId: this.state.resetId,
        };
        this.setState({ isLoading: 1 });
        UserService.saveResetPassword(payload)
          .then((res) => {
            if (res.error != null) {
              if (res.error?.response?.data?.message != null) {
                NotificationManager.error(
                  res.error.response.data.message,
                  "",
                  2000
                );
              } else {
                NotificationManager.error(
                  "Could not update password.",
                  "",
                  2000
                );
              }
              this.timeoutHandle = setTimeout(() => {
                this.setState({
                  isLoading: 0,
                  password: "",
                  repeatedPassword: "",
                });
                this.props.history.push("/authentication/my-account");
              }, 3000);
            } else {
              if (res["status"]) {
                NotificationManager.success(
                  "Password Reset Successfully.",
                  "",
                  2000
                );
                this.timeoutHandle = setTimeout(() => {
                  this.setState({ isLoading: 0 });
                  this.props.history.push("/authentication/my-account");
                }, 3000);
              } else {
                NotificationManager.error(res["message"], "", 2000);
                this.timeoutHandle = setTimeout(() => {
                  this.setState({
                    isLoading: 0,
                    password: "",
                    repeatedPassword: "",
                  });
                  this.props.history.push("/authentication/my-account");
                }, 3000);
              }
            }
          })
          .catch((err) => {
            NotificationManager.error(err, "", 2000);
            this.timeoutHandle = setTimeout(() => {
              this.setState({
                isLoading: 0,
                password: "",
                repeatedPassword: "",
              });
              this.props.history.push("/authentication/my-account");
            }, 3000);
          });
      }
    }
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 && <Loader />}
        <div className="checkout-white-bg">
          <div className="portal-checkout">
            <h4>Reset Password</h4>
            <div className="create-return" id="forgotpassword">
              <div className="create-return-right">
                <div className="create-return-form">
                  <Form.Group>
                    <label className="label">Create Password</label>
                    <Form.Control
                      type="password"
                      className="mb-0 create-signin"
                      id="txtPassword"
                      // placeholder="Password"
                      value={this.state.password}
                      onChange={this.handlePassword}
                      required
                    />
                  </Form.Group>
                  <p id="accept-terms">
                    Password must contain at least 8 characters, including 1
                    uppercase letter, 1 lowercase letter, 1 number, and 1
                    special character.
                  </p>
                  <Form.Group>
                    <label className="label">Password Strength</label>
                    <ProgressBar
                      now={this.state.passwordProgress}
                      className={`progress-accountcretaion ${
                        this.state.passwordProgress > 75
                          ? "strong"
                          : this.state.passwordProgress > 50
                          ? "medium"
                          : "weak"
                      }`}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label className="label">Confirm Password</label>
                    <Form.Control
                      type="password"
                      className="mb-0 create-signin"
                      id="txtRepeatedPassword"
                      // placeholder="Repeat Password"
                      value={this.state.repeatedPassword}
                      onChange={this.handleRepeatedPassword}
                      required
                    />
                  </Form.Group>
                  <button
                    className="Sign-In"
                    type="submit"
                    onClick={this.saveResetPassword}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-portalApp-checkout">
          <div className="portalApp-footer-img">
            <p>© 2024 Ciright Live. All rights reserved.</p>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
