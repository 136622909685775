import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Canvas from "../../components/canvas";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class NewHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="new-home">
        <div className="bg-canvas">
          <Canvas />
          <div className="newhome-img">
            <img
              src={require("../../assets/images/chick.png")}
              className="chick-img"
              alt="chick"
            />
            <img
              src={require("../../assets/images/text-banner-mobile.png")}
              className="blue-banner"
              alt="bluebanner"
              id="blue-banner"
            />
            <div className="location-img">
              <h4>
                Bader Field - Atlantic City, NJ <br></br>
                Memorial Day Weekend - 2024
              </h4>
            </div>
            <div className="concert-img" id="concert-img">
              <h4>Music - Technology - Culture</h4>
            </div>
          </div>
        </div>
        <div className="newhome-concert">
          <img
            src={require("../../assets/images/text-banner.png")}
            className="blue-banner"
            alt="bluebanner"
          />
          <div className="concert-img">
            <h4>Music - Technology - Culture</h4>
            <h5>Full Concert Lineup</h5>
            <h6>to be announced</h6>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewHome);
