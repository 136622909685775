import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppConfig from "../../config/app.config";
import {
  selectBrands,
  selectemployeeData,
  selecteventData,
} from "../../store/home/home.selectors";
import {
  brandsSyncStart,
  setSigninRedirectPath,
} from "../../store/home/home.actions";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loader from "../../components/loader/loader";
import CustomSwiper from "../../components/custom-swiper/swiper.component";
import StreamIframe from "../../components/iframe/StreamIframe";
import "./home.css";
import CountDown from "../../components/countdown/Countdown";
import Order from "../../components/model/Order";
import moment from "moment";
import { Link } from "react-router-dom";
import UserService from "../../services/user/user.service";
import { NotificationManager } from "react-notifications";
import WaitList from "../../components/model/join-the-wait-list-modal";

const mapStateToProps = (state) => {
  return {
    selectBrands: selectBrands(state),
    employeeData: selectemployeeData(state),
    eventData: selecteventData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setSigninRedirectPath,
      brandsSyncStart,
    },
    dispatch
  ),
});

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      brandsMap: [],
      checkingUpdate: false,
      isLogin: false,
      isShowModel: false,
      modelUrl: "",
      showTicketsSold: false,
      isNotificationShow: false,
      notificationShowMessageType: "",
      notificationShowMessage: "",
      // timeLeft: this.calculateTimeLeft(),
      intervalId: null,
      isStreamStarted: false,
      eventdate: "11/14/2024 18:00:00",
      eventName: "Ciright Live Stream",
      eventId: -1,
      eventDescription: "",
      eventImage: "",
      orderModel: false,
      orderId: null,
      convenienceFee: 0,
      price: 3.99,
      search: "",
      linkForIframe:
        AppConfig.API.LIVE_STREAM_LINK + AppConfig.API.LIVE_STREAM_BUCKET,
      emailAddress: "",
      isWaitList: false,
    };
  }

  async componentDidMount() {
    this.setState({
      search:
        window.location?.search != null && window.location?.search !== ""
          ? window.location?.search
          : "",
    });
    window.scrollTo(0, 0);
    // if (this.doesCookieExist("isPreviewComplete")) {
    //   await this.setState({ orderModel: true })
    // }
    const { brandsSyncStart, selectBrands } = this.props;
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      employeeId: this.props.employeeData?.employeeId,
      loginEmployeeId: this.props.employeeData?.employeeId,
      appId: AppConfig.API.APP_ID,
      timeZone: null,
    };
    this.props.setSigninRedirectPath({ signinRefirectPath: null });
    if (this.props.employeeData?.employeeId != null) {
      await this.setState({ isLogin: true });
    } else {
      await this.setState({ isLogin: false });
    }

    if (selectBrands.initialSynced && selectBrands.brandsList !== null) {
      await this.setState({ brandsMap: selectBrands.brandsList });
    } else {
      await brandsSyncStart(payload);
    }

    if (localStorage.getItem("eventData") != null) {
      const eventData = await JSON.parse(localStorage.getItem("eventData"));
      const webUrl =
        eventData.webUrl != null && eventData.webUrl !== ""
          ? eventData.webUrl
          : AppConfig.API.LIVE_STREAM_BUCKET;
      this.setState({
        eventId: eventData.eventId,
        eventName: eventData.eventName,
        eventdate: eventData.eventdate,
        eventDescription: eventData.projectDescription,
        linkForIframe: AppConfig.API.LIVE_STREAM_LINK + webUrl,
        eventImage: eventData.eventImage,
        convenienceFee:
          eventData.convenienceFee != null && !isNaN(eventData.convenienceFee)
            ? eventData.convenienceFee
            : 0,
        price:
          eventData.price != null && !isNaN(eventData.price)
            ? eventData.price
            : 3.99,
        isLoading: 0,
        orderId: eventData.orderId != null ? eventData.orderId : null,
        isStreamStarted: this.isStreamComplete(
          eventData.eventdate ?? "11/14/2024 18:00:00"
        ),
      });
    } else {
      this.setState({ isLoading: 0 });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectBrands } = this.props;

    if (selectBrands.initialSynced) {
      if (prevProps.selectBrands.brandsList !== selectBrands.brandsList) {
        this.setState({ brandsMap: selectBrands.brandsList });
      }
    }

    if (this.props.eventData !== prevProps.eventData) {
      const eventData = this.props.eventData;
      const webUrl =
        eventData.webUrl != null && eventData.webUrl !== ""
          ? eventData.webUrl
          : AppConfig.API.LIVE_STREAM_BUCKET;
      this.setState({
        eventId: eventData.eventId,
        eventName: eventData.eventName,
        eventdate: eventData.eventdate,
        eventDescription: eventData.projectDescription,
        linkForIframe: AppConfig.API.LIVE_STREAM_LINK + webUrl,
        eventImage: eventData.eventImage,
        convenienceFee:
          eventData.convenienceFee != null && !isNaN(eventData.convenienceFee)
            ? eventData.convenienceFee
            : 0,
        price:
          eventData.price != null && !isNaN(eventData.price)
            ? eventData.price
            : 3.99,
        isLoading: 0,
        orderId: eventData.orderId != null ? eventData.orderId : null,
        isStreamStarted: this.isStreamComplete(
          eventData.eventdate ?? "11/14/2024 18:00:00"
        ),
      });
    }

    if (
      this.state.isStreamStarted &&
      !this.doesCookieExist("isPreviewComplete")
    ) {
      const timeOut = setTimeout(() => {
        this.setState({ orderModel: true });
        const date = new Date();
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();

        document.cookie = `isPreviewComplete=1; ${expires}; path=/`;
        clearTimeout(timeOut);
      }, 30 * 1000);
    }
  }

  doesCookieExist(name) {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(nameEQ) === 0) {
        return true;
      }
    }
    return false;
  }

  closeNotification = () => {
    this.setState({
      isNotificationShow: false,
      notificationShowMessageType: "",
      notificationShowMessage: "",
    });
  };

  isStreamComplete(targetDate) {
    // const now = new Date(
    //   new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
    // )
    const now = new Date();
    const endDate = moment(targetDate, "MM/DD/YYYY hh:mm:ss").toDate();
    const diff = endDate - now;
    return diff <= 0;
  }

  addToWaitList = () => {
    if (
      this.props.employeeData != null &&
      this.props.employeeData.email != null &&
      this.props.employeeData.email !== ""
    ) {
      this.subscribeEmail(this.props.employeeData.email);
    } else {
      this.setState({ isWaitList: true });
    }
  };

  saveWiatList = async (email) => {
    await this.subscribeEmail(email);
  };

  subscribeEmail = async (email) => {
    this.setState({ isLoading: 2 });
    try {
      const res = await UserService.subscribeEmail({
        emailAddress: email,
      });
      if (res.error != null) {
        NotificationManager.error(
          res.error?.response?.data?.message ?? "Could not join the wait list.",
          "",
          3000
        );
        this.setState({ isLoading: 0 });
      } else {
        if (res["status"]) {
          NotificationManager.success(
            "Your are added into wait list.",
            "",
            3000
          );
          this.setState({
            emailAddress: "",
            isWaitList: false,
            isLoading: 0,
          });
        } else {
          NotificationManager.error("Could not join the wait list.", "", 3000);
          this.setState({ isLoading: 0 });
        }
      }
    } catch (err) {
      NotificationManager.error("Could not join the wait list.", "", 3000);
      this.setState({ isLoading: 0 });
    }
  };

  closeModal = () => {
    this.setState({ isWaitList: false });
  };

  render() {
    return (
      <div className="">
        {/* {this.state.orderId == null ? (
          <Order
            open={this.state.orderModel}
            onClose={() => {
              this.setState({ orderModel: false });
            }}
          />
        ) : (
          ""
        )} */}

        {this.state.isWaitList ? (
          <WaitList
            isOpen={this.state.isWaitList}
            onHide={this.closeModal}
            saveWiatList={this.saveWiatList}
          />
        ) : (
          ""
        )}

        {this.state.isLoading === 1 ? (
          <Loader showLogo />
        ) : (
          <>
            {this.state.isLoading === 2 ? <Loader /> : ""}

            {AppConfig.API.IS_ANNOUNCEMENT === 0 ? (
              <>
                {/* live Streaming */}
                One
              </>
            ) : AppConfig.API.IS_ANNOUNCEMENT === 1 ? (
              <>
                {/* Count Down */}
                {!this.state.isStreamStarted ? (
                  <>
                    <CustomSwiper
                      orderId={this.state.orderId}
                      addToWaitList={this.addToWaitList}
                    />
                    <div className="event-name-banner">
                      {AppConfig.API.APP_ID === 2836 && (
                        <>
                          {AppConfig.API.EVENT_ID === 2980039 ? (
                            <p className="event-banner-text">
                              Eagles Pre Game <span>Pep Rally </span> – LIVE
                              Streaming <span> from Philadelphia </span>
                            </p>
                          ) : (
                            <p className="event-banner-text">
                              {/* {this.state.eventName} */}
                              {/* Secret <span>Pop-Up Show </span> – LIVE Streaming{" "}
                              <span> from Dublin Ireland </span> */}
                              Watch <span>Grammy Nominee</span> Cian Ducrot Live
                              - Exclusive Stream <span>Now Available</span>
                            </p>
                          )}
                        </>
                      )}
                      {AppConfig.API.APP_ID === 2838 && (
                        <p className="event-banner-text">
                          {/* {this.state.eventName} */}
                          <span>MJ</span> The <span>Evolution</span> Tribute
                          Show
                        </p>
                      )}
                    </div>
                    <CountDown
                      time={
                        this.state.search !== ""
                          ? "11/14/2024 18:00:00"
                          : this.state.eventdate
                      }
                      //time={"11/13/2024 14:01:00"}
                      orderId={this.state.orderId}
                      price={this.state.price}
                      callback={() => this.setState({ isStreamStarted: true })}
                    />
                  </>
                ) : (
                  <StreamIframe
                    hide={
                      this.doesCookieExist("isPreviewComplete") ||
                      this.state.orderModel
                    }
                    link={this.state.linkForIframe}
                    orderId={this.state.orderId}
                    handleClick={() => this.setState({ orderModel: true })}
                  />
                )}
              </>
            ) : (
              AppConfig.API.IS_ANNOUNCEMENT === 2 && (
                <>
                  {/* miss the Show */}
                  <CustomSwiper
                    orderId={this.state.orderId}
                    addToWaitList={this.addToWaitList}
                  />
                  <div className="event-name-banner">
                    {AppConfig.API.APP_ID === 2836 && (
                      <p className="event-banner-text">
                        {/* {this.state.eventName} */}
                        {/* Secret <span>Pop-Up Show </span> – Recorded LIVE{" "}
                        <span> from O'Connell Street,Dublin Ireland </span> */}
                        Watch <span>Grammy Nominee</span> Cian Ducrot Live - Exclusive Stream <span>Now Available</span>
                      </p>
                    )}
                  </div>
                  {this.state.orderId != null &&
                  this.state.orderId !== -1 &&
                  this.state.orderId !== "" ? (
                    ""
                  ) : (
                    <div className="countdown-container">
                      <h3
                        className="countdown-info"
                        style={{ color: "#fff", fontSize: "36px" }}
                      >
                        Miss the Show?
                      </h3>

                      <div className="countdown-order">
                        <Link
                          to="/checkout-ticket"
                          style={{
                            width: "min-content",
                            display: "block",
                            marginInline: "auto",
                          }}
                        >
                          <button
                            className="countdown-btn"
                            style={{ cursor: "pointer" }}
                          >
                            Order Here
                            {/* $
                              {this.state.price != null && this.state.price >= 0
                                ? this.state.price
                                : 3.99} */}
                          </button>
                        </Link>
                        {/* <button
                            className="countdown-btn"
                            style={{ cursor: "pointer" }}
                            onClick={this.addToWaitList}
                          >
                            Join The Wait List
                          </button> */}
                        <p className="count-replay">(Concert Replay)</p>
                      </div>
                    </div>
                  )}
                </>
              )
            )}
            {AppConfig.API.IS_VIEW_BANIIFIT === 1 && (
              <div className="portals-network">
                <div className="portals-network-container">
                  <div className="mobile-brand" style={{ alignItems: "end" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          fontFamily: "Avenir Next",
                          fontSize: "18px",
                          Color: "#fff",
                          fontWeight: "500",
                          textAlign: "left",
                        }}
                      >
                        For the benefit of:
                      </p>
                      {/* <img
                      src={require("../../assets/images/CirightLive/minding-creative.png")}
                      alt="ciright"
                      className="brand-list-1"
                      style={{ marginRight: "1rem", marginBottom: "1rem" }}
                    /> */}
                      <img
                        src={require("../../assets/images/CirightLive/barnardos.png")}
                        alt="ciright"
                        className="brand-list-2"
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="portal-brandlist">
                {this.state.brandsMap?.length > 0 ? (
                  <BrandList
                    resultMap={this.state.brandsMap}
                    id={9}
                    tagName="Brands"
                    viewAllUrl="/view-all/brands"
                  />
                ) : (
                  ""
                )}
              </div> */}

                <div className="portals-network-container">
                  <div className="mobile-portallogo">
                    {/* <APPLOGOWHITE /> */}
                  </div>

                  {/* <div className="portal-description">
                  <p>THE PORTALS NETWORK</p> 
                  <p>
                    Portals are technology art sculptures. They connect to a
                    network of identical sculptures all over the planet. Each
                    Portal is located in the public space, providing a
                    real-time, unfiltered livestream 24/7 that is frequently
                    rotating between different Portal locations on Earth.
                  </p>
                </div>

                <div className="types-ofportals">
                  <img
                    src={require("../../assets/images/Portal/new-york.png")}
                    alt="portal-img"
                  />
                  <img
                    src={require("../../assets/images/Portal/poland.png")}
                    alt="portal-img"
                  />
                </div>
                <div className="types-ofportals">
                  <img
                    src={require("../../assets/images/Portal/lithuania.png")}
                    alt="portal-img"
                  />
                  <img
                    src={require("../../assets/images/Portal/dublin.png")}
                    alt="portal-img"
                  />
                </div> */}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
