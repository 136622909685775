import React from "react";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";
import { bindActionCreators } from "redux";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class alertbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      isShow: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.interval = setInterval(() => this.setState({ isShow: 0 }), 20000);
  }

  closeNotification = () => {
    this.setState({ isShow: false });
  };

  render() {
    return (
      <div aria-live="polite" aria-atomic="true" className="alertbox-main">
        <Toast
          className="alertbox"
          show={this.state.isShow}
          onClose={this.closeNotification}
        >
          {/* alertbox-error
            alertbox-warning 
            alertbox-success*/}
          <div className="alertbox-message" id="alertbox-success">
            <p>
              Password must contain at least one lowercase letter, one
              uppercase, a number, and a special character and a length between
              8 to 20 characters.
            </p>
          </div>
          <div className="alertbox-close" onClick={this.closeNotification}>
            <img
              src={require("../../assets/images/alert-close.png")}
              className="alert-close"
              alt="close"
            />
          </div>
        </Toast>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(alertbox);
