import { Component } from "react";
import Loader from "../../components/loader/loader";

export default class MyLink extends Component {
  componentDidMount() {
    const code = this.props.match?.params.userName;
    window.location.href =
      "https://influencer.ciright.live/referral-link/1294120/srs9nhx50t";
  }

  render() {
    return (
      <>
        <Loader />
      </>
    );
  }
}
