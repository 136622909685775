import { Component } from "react";
import { Modal } from "react-bootstrap";
// import ReactJWPlayer from "react-jw-player";

class VideoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        dialogClassName="modal-fullscreen"
        id="edu-modal-set"
      >
        <Modal.Header
          closeButton
          onHide={() => this.props.onHide()}
          backdrop="static"
        ></Modal.Header>
        <Modal.Body id="main-screen-modal">
          {/* <ReactJWPlayer
            playerId="Video"
            playerScript="https://cdn.jwplayer.com/libraries/zFFedrqG.js"
            file={this.state.modelUrl}
            isAutoPlay={true}
            // aspectRatio="20:9"
            customProps={{
              playbackRateControls: [1, 1.25, 1.5],
              cast: {},
            }}
          /> */}
        </Modal.Body>
      </Modal>
    );
  }
}

export default VideoModal;
