import React from "react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { gsap } from "gsap";
import { selectemployeeData } from "../../store/home/home.selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppConfig from "../../config/app.config";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import thumbnail from "../../assets/images/CirightLive/Cian-Thumbnail.png";
import "./swiper.css";

const mapStateToProps = (state) => ({
  employeeData: selectemployeeData(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

const gsapAnimate = {
  getData: (elem) => {
    const option = {
      opacity: 0,
      scale: 1,
      position: {
        x: 0,
        y: 0,
      },
      ease: "",
      duration: 1,
      delay: 0.4,
      rotate: 0,
    };
    if (elem !== undefined) {
      option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0);
      option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0);
      option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0);
      option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1);
      option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0);
      option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, 0.4);
      option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5);
      option.ease = gsapAnimate.validValue(elem.dataset.iqEase, "");

      const setOption = {
        opacity: option.opacity,
        scale: option.scale,
        x: option.position.x,
        y: option.position.y,
        ease: option.ease,
        rotate: option.rotate,
        duration: option.duration,
        delay: option.delay,
      };

      return setOption;
    } else {
      return { opacity: 0 };
    }
  },
  onStart: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    gsap.from(elem, setOption);
  },

  onEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    gsap.to(elem, setOption);
  },

  onStartEnd: (elem) => {
    const setOption = gsapAnimate.getData(elem);
    const setEndOption = gsapAnimate.getData(elem);

    setEndOption.opacity = 1;
    setEndOption.x = 0;
    setEndOption.y = 0;
    setEndOption.rotate = 0;
    setEndOption.scale = 1;

    gsap.fromTo(elem, setOption, setEndOption);
  },
  validValue: (attr, defaultVal) => {
    if (attr !== undefined && attr !== null) {
      return Number(attr);
    }
    return Number(defaultVal);
  },
};

SwiperCore.use([Navigation, Pagination, Autoplay]);

export class CustomSwiper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeData: null,
      images: [],
      swiper: null,
      orderId: props.orderId,
      showText: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (AppConfig.API.APP_ID === 2838) {
      this.setState({
        images: [
          {
            imageUrl: require("../../assets/images/Portal/pre-banner1.png"),
          },
          {
            imageUrl: require("../../assets/images/Portal/pre-banner2.png"),
          },
          {
            imageUrl: require("../../assets/images/Portal/pre-banner3.png"),
          },
          {
            imageUrl: require("../../assets/images/Portal/pre-banner4.png"),
          },
        ],
      });
    } else {
      if (AppConfig.API.EVENT_ID === 2980039) {
        this.setState({
          images: [
            {
              imageUrl: require("../../assets/images/CirightLive/eagles-banner3.png"),
              mobileImageUrl: require("../../assets/images/CirightLive/eagles-banner-mobile3.png"),
              onclickLink: "/authentication/my-account",
            },
          ],
        });
      } else {
        if (AppConfig.API.IS_ANNOUNCEMENT === 2) {
          this.setState({
            images: [
              {
                imageUrl: require("../../assets/images/CirightLive/Cian-portal-banner.png"),
                imageText: "Cian Ducrot – Pop-up Show",
              },
            ],
          });
        } else if (AppConfig.API.IS_ANNOUNCEMENT === 1) {
          this.setState({
            images: [
              {
                imageUrl: require("../../assets/images/CirightLive/Cian1.png"),
                imageText: "JUST ANNOUNCED – CIAN DUCROT",
              },
              {
                imageUrl: require("../../assets/images/CirightLive/Cian2.png"),
                imageText: "JUST ANNOUNCED – CIAN DUCROT",
              },
              {
                imageUrl: require("../../assets/images/CirightLive/Cian3.png"),
                imageText: "JUST ANNOUNCED – CIAN DUCROT",
              },
            ],
          });
        } else {
          this.setState({
            images: [
              {
                imageUrl: require("../../assets/images/CirightLive/cirightlive-dublin1.png"),
                imageText: "unique live global event streaming",
              },
              {
                imageUrl: require("../../assets/images/CirightLive/cirightlive-dublin1.png"),
                imageText: "where every fan’s an influencer",
              },
            ],
          });
        }
      }
    }
  }

  animationInit = () => {
    if (
      document.querySelector(".swiper-container .swiper-slide-active") !== null
    ) {
      const gsapElem = document
        .querySelector(".swiper-container .swiper-slide-active")
        .querySelectorAll('[data-iq-gsap="onStart"]');

      Array.from(gsapElem, (elem) => {
        return gsapAnimate.onStartEnd(elem);
      });
    }
  };

  addToWaitList = () => {
    this.props.addToWaitList();
  };

  render() {
    return (
      <div
        style={{ position: "relative" }}
        id="top-swiper"
        className="swiper-container"
      >
        {/* <ReactPlayer
          url="https://common-document-dest.s3.us-east-1.amazonaws.com/LSDublinNoV8PortalPromo/2M/hls2M-LSDublinNoV8PortalPromo.ts.m3u8"
          playing
          controls
          width="100%"
          height="712px"
          config={{
            file: {
              attributes: {
                preload: "auto",
              },
            },
          }}///Users/macmini/ciright/workspace/todays-portal/src/assets/images/CirightLive/Cian-Thumbnail.png
          light={thumbnail}
          playIcon={<button onClick={()=> this.setState({showText: false})} className="play-btn">▶</button>}
        /> */}
        {AppConfig.API.EVENT_ID === 2980039 ? (
          <>
            <Swiper
              onSwiper={(swiper) => this.setState({ swiper: swiper })}
              onInit={() => {
                this.animationInit();
              }}
              onSlideChangeTransitionStart={() => this.animationInit()}
              loop={this.state.images.length > 1}
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: ".swiper-button-next-home",
                prevEl: ".swiper-button-prev-home",
              }}
            >
              {this.state.images.map((item, key) => (
                <SwiperSlide className="" as="li" key={key}>
                  <Link
                    to={
                      item.onclickLink != null && item.onclickLink !== ""
                        ? item.onclickLink
                        : "#"
                    }
                  >
                    <img
                      src={item.imageUrl}
                      alt={`Slide ${key + 1}`}
                      className="swiper-web-images"
                    />
                  </Link>

                  {item.mobileImageUrl != null &&
                    item.mobileImageUrl !== "" && (
                      <Link
                        to={
                          item.onclickLink != null && item.onclickLink !== ""
                            ? item.onclickLink
                            : "#"
                        }
                      >
                        <img
                          src={item.mobileImageUrl}
                          alt={`Slide ${key + 1}`}
                          className="swiper-mobile-images"
                        />
                      </Link>
                    )}

                  {item.imageText != null && item.imageText !== "" ? (
                    <p className="banner-text">{item.imageText}</p>
                  ) : (
                    ""
                  )}
                  {AppConfig.API.IS_ANNOUNCEMENT === 2 ? (
                    <>
                      {this.state.orderId != null &&
                      this.state.orderId !== -1 &&
                      this.state.orderId !== "" ? (
                        ""
                      ) : (
                        <></>
                        // <div className="banner-button">
                        //   <Link
                        //     to="/checkout-ticket"
                        //     style={{
                        //       width: "min-content",
                        //       display: "block",
                        //       marginInline: "auto",
                        //     }}
                        //   >
                        //     <button
                        //       className="countdown-btn"
                        //       style={{ cursor: "pointer" }}
                        //     >
                        //       Order Here
                        //     </button>
                        //   </Link>
                        //   <button
                        //     className="countdown-btn"
                        //     style={{ cursor: "pointer" }}
                        //     onClick={this.addToWaitList}
                        //   >
                        //     Join The Wait List
                        //   </button>
                        // </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              onClick={() => {
                if (this.state.swiper != null) {
                  this.state.swiper.slideNext();
                }
              }}
              className="swiper-button-next"
            >
              <i className="fa fa-chevron-right"></i>
            </div>
            <div
              onClick={() => {
                if (this.state.swiper != null) {
                  this.state.swiper.slidePrev();
                }
              }}
              className="swiper-button-prev"
            >
              <i className="fa fa-chevron-left"></i>
            </div>
          </>
        ) : (
          // <ReactPlayer
          //   url="https://common-document-dest.s3.us-east-1.amazonaws.com/LSDublinNoV8PortalPromo/2M/hls2M-LSDublinNoV8PortalPromo.ts.m3u8"
          //   playing
          //   controls
          //   width="100%"
          //   height="100%"
          //   config={{
          //     file: {
          //       attributes: {
          //         preload: "auto",
          //       },
          //     },
          //   }}
          // />
          <>
            <ReactPlayer
              url="https://common-document-dest.s3.us-east-1.amazonaws.com/LSDublinNoV8PortalPromo/2M/hls2M-LSDublinNoV8PortalPromo.ts.m3u8"
              playing
              controls
              width="100%"
              height="712px"
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }} ///Users/macmini/ciright/workspace/todays-portal/src/assets/images/CirightLive/Cian-Thumbnail.png
              light={thumbnail}
              playIcon={
                <button
                  onClick={() => this.setState({ showText: false })}
                  className="play-btn"
                >
                  ▶
                </button>
              }
            />

            <div
              className={`${
                this.state.showText ? "" : "hide-text"
              }banner-top-overlay-text`}
            >
              Grammy Award Nominee
            </div>
            <div
              className={`${
                this.state.showText ? "" : "hide-text"
              }banner-bottom-overlay-text`}
            >
              Cian Ducrot
            </div>
          </>
        )}
        <style>{`
                    #top-swiper .swiper-container {
                        width: 100%;
                        height: 672px;
                      }
            
                      #top-swiper .swiper-slide {
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                      }
            
                      #top-swiper .swiper-slide img {
                        width: 100%;
                        object-fit: cover;
                        height: 672px;
                      }
                      @media only screen and (max-width: 600px) {
                        #top-swiper .swiper-container {
                            height: 279px;
                          }
                          #top-swiper .swiper-slide img {
                            width: 100%;
                            height: 279px;
                            object-fit: cover;
                          }
                      }
                `}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSwiper);
