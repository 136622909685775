const SignUpActionTypes = {
  SIGNUP_START: "SIGNUP_START",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  MERCHANT_SIGNUP_START: "MERCHANT_SIGNUP_START",
  MERCHANT_SIGNUP_SUCCESS: "MERCHANT_SIGNUP_SUCCESS",
  MERCHANT_SIGNUP_FAILURE: "MERCHANT_SIGNUP_FAILURE",
};

export default SignUpActionTypes;
