//router
import LayoutsRoute from "./router/layouts-route"

//scss files
import "./assets/css/bootstrap.min.css"
import "./assets/css/typography.css"
import "./assets/css/style.css"
import "./assets/css/responsive.css"
import "./assets/css/dstyles.css"

import { NotificationContainer } from "react-notifications"
import { useEffect } from "react"
import AppConfig from "./config/app.config"

function App() {
  useEffect(() => {
    if (AppConfig.API.APP_ID === 2836) {
      document.title = "Ciright Live"
      // const linkMain = document.querySelector("link[rel*='icon']")
      // const link = document.querySelector("link[rel='apple-touch-icon']")
      // if (link && linkMain) {
      //   link.href=require('./assets/images/CirightLive/')
      // }
    } else {
      document.title = "Veuit Live"
    }
  }, [AppConfig.API.APP_ID])
  return (
    <div className="App">
      <LayoutsRoute />
      <NotificationContainer />
    </div>
  )
}

export default App
