import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { selectemployeeData } from "../../store/home/home.selectors";
import { NotificationManager } from "react-notifications";
import Loader from "../../components/loader/loader";
import { ReactComponent as Edit } from "../../assets/images/Edit.svg";
import validator from "validator";

const removeExtraSpace = (s) => s.trim().replace(/\s/g, "");

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class AccountInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      profile: null,

      isEditable: false,

      name: "",
      profilePic: null,
      profilePicSource: null,
      email: "",
      phone: "",
      // password: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeData?.employeeId == null) {
      this.props.history.push("/");
    } else {
      this.getEmployeeProfileData();
    }
  }

  getEmployeeProfileData = async () => {
    if (this.props.employeeData?.employeeId != null) {
      try {
        const res = await UserService.getEmployeeProfileDetail(
          this.props.employeeData?.employeeId
        );
        if (res.error != null) {
          if (res.error?.response?.data?.message != null) {
            NotificationManager.error(
              "",
              res.error.response.data.message,
              3000
            );
          } else {
            NotificationManager.error("", "Could not get Profile Data.", 3000);
          }
          this.setState({ isLoading: 0 });
        } else {
          if (res["status"]) {
            const data = res["data"];
            if (data == null) {
              NotificationManager.error(
                "",
                "Could not get Profile Data.",
                3000
              );
            } else {
              const phoneNumber = data.phone.replace(/\D/g, "");
              let formatted = "";
              if (phoneNumber.length > 3) {
                formatted += phoneNumber.substr(0, 3) + "-";
                if (phoneNumber.length > 6) {
                  formatted +=
                    phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
                } else {
                  formatted += phoneNumber.substr(3);
                }
              } else {
                formatted = phoneNumber;
              }
              this.setState({
                profile: data,
                profilePic: null,
                profilePicSource:
                  AppConfig.API.USER_PROFILE +
                  this.props.employeeData?.employeeId,
                name: data.name,
                email: data.email,
                phone: formatted,
                // password: "",

                isLoading: 0,
              });
            }
          } else {
            NotificationManager.error("", "Could not get Profile Data.", 3000);
            this.setState({ isLoading: 0 });
          }
        }
      } catch (err) {
        this.setState({ isLoading: 0 });
      }
    }
  };

  handleProfilePic = (event) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ profilePicSource: e.target.result });
    };
    reader.readAsDataURL(event.target.files[0]);
    this.setState({ profilePic: event.target.files[0] });
  };
  handleName = (event) => {
    this.setState({ name: event.target.value });
  };
  handleEmail = (event) => {
    this.setState({ email: removeExtraSpace(event.target.value) });
  };
  handlePhone = (event) => {
    const phoneNumber = event.target.value.replace(/\D/g, "");
    let formatted = "";
    if (phoneNumber.length > 3) {
      formatted += phoneNumber.substr(0, 3) + "-";
      if (phoneNumber.length > 6) {
        formatted += phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6);
      } else {
        formatted += phoneNumber.substr(3);
      }
    } else {
      formatted = phoneNumber;
    }
    this.setState({ phone: formatted });
  };
  // handlePassword = (event) => {
  //   this.setState({
  //     password: event.target.value,
  //   });
  // }

  saveAccountInfo = async () => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const fNumber = this.state.phone.split("-").join("");
    if (this.state.name.trim() === "") {
      NotificationManager.warning("Please Enter Name.", "", 2000);
    } else if (this.state.email.trim() === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else if (fNumber.trim() === "") {
      NotificationManager.warning("Please Enter Phone Number.");
    } else if (!pattern.test(fNumber)) {
      NotificationManager.warning("Please Enter Valid Phone Number.", "", 2000);
    } else if (fNumber.length !== 10) {
      NotificationManager.warning("Please Enter Valid Phone Number.", "", 2000);
    } else {
      this.setState({ isLoading: 1 });
      if (this.props.employeeData?.employeeId != null) {
        try {
          const payload = {
            photo: this.state.profilePicSource.split(",")[1],
            name: this.state.name,
            phone: fNumber,
          };
          const res = await UserService.saveEmployeeProfileDetail(
            this.props.employeeData?.employeeId,
            payload
          );
          if (res.error != null) {
            if (res.error?.response?.data?.message != null) {
              NotificationManager.error(
                "",
                res.error.response.data.message,
                3000
              );
            } else {
              NotificationManager.error(
                "",
                "Could not save Profile Data.",
                3000
              );
            }
            this.setState({ isLoading: 0 });
          } else {
            if (res["status"]) {
              const data = res["data"];
              if (data == null) {
                NotificationManager.error(
                  "",
                  "Could not save Profile Data.",
                  3000
                );
                this.setState({ isLoading: 0 });
              } else {
                this.setState({
                  profilePic: null,
                  isLoading: 0,
                  isEditable: false,
                });
              }
            } else {
              NotificationManager.error(
                "",
                "Could not save Profile Data.",
                3000
              );
              this.setState({ isLoading: 0 });
            }
          }
        } catch (err) {
          this.setState({ isLoading: 0 });
        }
      }
    }
  };

  render() {
    return this.props.employeeData != null ? (
      <div className="account-profileinfo">
        {this.state.isLoading ? <Loader /> : ""}
        <div className="profile-picture">
          <div className="profile-img" style={{ position: "relative" }}>
            {this.state.profilePicSource != null && (
              <img src={this.state.profilePicSource} alt="" />
            )}
            {this.state.isEditable === true && (
              <>
                <label
                  htmlFor="files"
                  // className="btn"
                  className="profiledetails-edit"
                >
                  <Edit />
                </label>
                <input
                  className="input-field"
                  id="files"
                  type="file"
                  accept="image/*"
                  onChange={this.handleProfilePic}
                />
              </>
            )}
          </div>
          <h4>Profile picture </h4>
        </div>
        <h5>Profile</h5>
        <div className="account-profileinfo-field">
          <div className="account-profileinfo-left">
            <p>Name</p>
          </div>
          <div className="account-profileinfo-right">
            <Form.Group>
              <Form.Control
                type="text"
                className="mb-0 account-name"
                maxLength="20"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleName}
                disabled={!this.state.isEditable}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
              />
            </Form.Group>
          </div>
        </div>
        <div className="account-profileinfo-field">
          <div className="account-profileinfo-left">
            <p>Email</p>
          </div>
          <div className="account-profileinfo-right">
            <Form.Group>
              <Form.Control
                type="email"
                className="mb-0 account-name"
                id="txtEmail"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleEmail}
                disabled={true}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                required
              />
            </Form.Group>
          </div>
        </div>
        <div className="account-profileinfo-field">
          <div className="account-profileinfo-left">
            <p>Phone</p>
          </div>
          <div className="account-profileinfo-right">
            <Form.Group>
              <Form.Control
                type="email"
                className="mb-0 account-name"
                id="txtPhone"
                placeholder="Phone Number"
                value={this.state.phone}
                onChange={this.handlePhone}
                disabled={!this.state.isEditable}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
                autoComplete="off"
                required
              />
            </Form.Group>
          </div>
        </div>
        {/* <div className="account-profileinfo-field">
          <div className="account-profileinfo-left">
            <p>Password</p>
          </div>
          <div className="account-profileinfo-right">
            <Form.Group id="sign-group">
              <Form.Control
                className="mb-0 account-name"
                maxLength="20"
                id="txtRepeatedPassword"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handlePassword}
                disabled={!this.state.isEditable}
                required
              />
            </Form.Group>
          </div>
        </div> */}
        {this.state.isEditable ? (
          <div className="save-cancelbtn">
            <button className="save-Profile" onClick={this.saveAccountInfo}>
              Save
            </button>
            <button
              className="cancel-Profile"
              onClick={() =>
                this.setState({
                  isEditable: false,

                  profilePic: null,
                  profilePicSource:
                    AppConfig.API.USER_PROFILE +
                    this.props.employeeData?.employeeId,
                  name: this.state.profile.name,
                  email: this.state.profile.email,
                  // password: "",
                })
              }
            >
              Cancel
            </button>
          </div>
        ) : (
          <button
            className="Edit-Profile"
            onClick={() => this.setState({ isEditable: true })}
          >
            Edit Profile
          </button>
        )}
      </div>
    ) : (
      ""
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo);
