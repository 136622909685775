import { createSelector } from "reselect";

const selectData = (state) => state.home;

export const selectLocation = createSelector([selectData], (home) => {
  return { location: home.location };
});

export const selectSigninRedirectPath = createSelector([selectData], (home) => {
  return home.signinRedirectPath;
});

export const selectBrands = createSelector([selectData], (home) => {
  return {
    initialSynced: home.initialSynced,
    brandsList: home.brandsList,
    message: home.message,
  };
});

export const selectMasterEvent = createSelector([selectData], (home) => {
  return home.masterEvent;
});

export const selectCart = createSelector([selectData], (home) => {
  return home.cart;
});

export const selectemployeeData = createSelector([selectData], (home) => {
  return home.employeeData;
});

export const selecteventData = createSelector([selectData], (home) => {
  return home.eventData;
});
