import React from "react";
import { connect } from "react-redux";
import { Form, Card, Accordion } from "react-bootstrap";
import InputMask from "react-input-mask";
import { bindActionCreators } from "redux";
import { ReactComponent as MasterCard } from "../../assets/images/credit-cards_mastercard.svg";
import Loader from "../../components/loader/loader";
import { NotificationManager } from "react-notifications";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { selectemployeeData } from "../../store/home/home.selectors";
import { setSigninRedirectPath } from "../../store/home/home.actions";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import ContextAwareAccordionToggle from "../../components/context_aware_accordion_toggle";

const mapStateToProps = (state) => {
  return { employeeData: selectemployeeData(state) };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ setSigninRedirectPath }, dispatch),
});

export class AddCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      isShow: true,
      cardMap: [],
      cardNumber: "",
      expYear: "",
      expMonth: "",
      CVV: "",
      cardExpiration: null,
      cardId: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeData?.employeeId != null) {
      this.setState({ isLoading: 1 });
      this.getAllCardList();
    } else {
      this.props.setSigninRedirectPath({
        signinRedirectPath: "/account-info",
      });
      this.props.history.push("/authentication/my-account");
    }
  }

  getAllCardList = async () => {
    try {
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        appId: AppConfig.API.APP_ID,
        loginEmployeeId: this.props.employeeData?.employeeId,
      };
      const res = await UserService.getAllCardList(payload);
      if (res.error != null) {
        if (
          res.error.response != null &&
          res.error.response.data != null &&
          res.error.response.data.message != null
        ) {
          NotificationManager.error(res.error.response.data.message, "", 2000);
        } else if (res.error.message != null) {
          NotificationManager.error("Could not get Card List.", "", 3000);
        }
        this.setState({ isLoading: 0, cardMap: [] });
      } else {
        if (res["status"]) {
          var data = res["data"];
          for (let i = 0; i < data.length; i++) {
            const rowData = data[i];
            if (rowData.isDefaultCard === 1) {
              this.setState({
                cardId: rowData.cardId,
              });
            }
          }
          if (data.length > 0) {
            this.setState({ cardMap: data, isLoading: 0 });
          } else {
            NotificationManager.error("There is not Added Any Card.", "", 3000);
            this.setState({ isLoading: 0, cardMap: [] });
          }
        } else {
          NotificationManager.error("Could not get Card List.", "", 3000);
          this.setState({ isLoading: 0, cardMap: [] });
        }
      }
    } catch (err) {
      this.setState({ isLoading: 0, cardMap: [] });
    }
  };

  handleCardNumber = (event) => {
    this.setState({ cardNumber: event.target.value });
  };

  handleCardExpiration = (event) => {
    this.setState({ cardExpiration: event.target.value });
  };

  handleCardCVC = (event) => {
    this.setState({ cardCVC: event.target.value });
  };

  addCard = async () => {
    const timeDiff = moment(this.state.cardExpiration, "MM/YY").diff(
      moment().month(moment().month() - 1)
    );
    if (this.state.cardNumber.split(" ").join("").length !== 16) {
      NotificationManager.warning("Please Enter valid Card Number.", "", 2000);
    } else if (
      this.state.cardExpiration.split("/").join("").length !== 4 ||
      this.state.cardExpiration === "00/00"
    ) {
      NotificationManager.warning(
        "Please Enter valid Card Expiration Date.",
        "",
        2000
      );
    } else if (isNaN(timeDiff) || timeDiff <= 0) {
      NotificationManager.warning(
        "Please Enter valid Card Expiration Date.",
        "",
        2000
      );
    } else if (
      this.state.cardCVC.length !== 4 &&
      this.state.cardCVC.length !== 3
    ) {
      NotificationManager.warning("Please Enter valid Card CVV.", "", 2000);
    } else {
      this.setState({ isLoading: 2 });
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        appId: AppConfig.API.APP_ID,
        loginEmployeeId: this.props.employeeData?.employeeId,
        expMonth: this.state.cardExpiration.split("/")[0],
        expYear: this.state.cardExpiration.split("/")[1],
        //cardNumber: this.state.cardNumber.split(" ").join(""),
        cardNumber: "tok_unionpayx",
        cardCVC: this.state.cardCVC,
      };
      const res = await UserService.addStripeCard(payload);
      if (res.error != null) {
        if (
          res.error.response != null &&
          res.error.response.data != null &&
          res.error.response.data.message != null
        ) {
          NotificationManager.error(res.error.response.data.message, "", 2000);
        } else {
          NotificationManager.warning("Somthing went wrong!.", "", 2000);
        }
        this.setState({ isLoading: 0 });
      } else {
        if (res["status"]) {
          NotificationManager.success("Your Card added Successful.", "", 2000);
          this.setState({
            isLoading: 0,
          });
          this.getAllCardList();
        } else {
          NotificationManager.warning(res["message"], "", 2000);
          this.setState({ isLoading: 0 });
        }
      }
    }
  };

  makePayment = async () => {
    this.setState({ isLoading: 2 });
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      loginEmployeeId: this.props.employeeData?.employeeId,
      cardId: this.state.cardId,
    };
    const res = await UserService.makeStripePayment(payload);
    if (res.error != null) {
      if (
        res.error.response != null &&
        res.error.response.data != null &&
        res.error.response.data.message != null
      ) {
        NotificationManager.error(res.error.response.data.message, "", 2000);
      } else {
        NotificationManager.warning("Somthing went wrong!.", "", 2000);
      }
      this.setState({ isLoading: 0 });
    } else {
      if (res["status"]) {
        NotificationManager.success("Your Payment is Successful.", "", 2000);
        this.setState({
          isLoading: 0,
        });
        this.getAllCardList();
      } else {
        NotificationManager.warning(res["message"], "", 2000);
        this.setState({ isLoading: 0 });
      }
    }
  };

  deleteCard = async (cardId) => {
    this.setState({ isLoading: 2 });
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      cardId: cardId,
    };
    const res = await UserService.deleteCard(
      payload,
      this.props.employeeData?.employeeId
    );
    if (res.error != null) {
      if (
        res.error.response != null &&
        res.error.response.data != null &&
        res.error.response.data.message != null
      ) {
        NotificationManager.error(res.error.response.data.message, "", 2000);
      } else {
        NotificationManager.warning("Somthing went wrong!.", "", 2000);
      }
      this.setState({ isLoading: 0 });
    } else {
      if (res["status"]) {
        NotificationManager.success("Your Card Deleted Successful.", "", 2000);
        this.setState({
          isLoading: 0,
        });
        this.getAllCardList();
      } else {
        NotificationManager.warning(res["message"], "", 2000);
        this.setState({ isLoading: 0 });
      }
    }
  };

  setDefaultCard = async (cardId) => {
    this.setState({ isLoading: 2 });
    const payload = {
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      cardId: cardId,
    };
    const res = await UserService.setDefaultCard(
      payload,
      this.props.employeeData?.employeeId
    );
    if (res.error != null) {
      if (
        res.error.response != null &&
        res.error.response.data != null &&
        res.error.response.data.message != null
      ) {
        NotificationManager.error(res.error.response.data.message, "", 2000);
      } else {
        NotificationManager.warning("Somthing went wrong!.", "", 2000);
      }
      this.setState({ isLoading: 0 });
    } else {
      if (res["status"]) {
        NotificationManager.success("Your Card Updated Successful.", "", 2000);
        this.setState({
          isLoading: 0,
        });
        this.getAllCardList();
      } else {
        NotificationManager.warning(res["message"], "", 2000);
        this.setState({ isLoading: 0 });
      }
    }
  };

  render() {
    return (
      <div className="checkout-white-bg">
        {this.state.isLoading === 1 ? (
          <Loader showLogo />
        ) : (
          <>
            {this.state.isLoading === 2 ? <Loader showLogo /> : ""}
            <div className="payment-info-container">
              <Accordion defaultActiveKey="0">
                <Card>
                  <ContextAwareAccordionToggle eventKey="0">
                    <Card.Header>Select Your Card and Pay</Card.Header>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse
                    eventKey="0"
                    style={{
                      borderLeft: "#000000 2px solid",
                      borderRight: "#000000 2px solid",
                    }}
                  >
                    <Card.Body>
                      {this.state.cardMap != null &&
                      this.state.cardMap.length > 0 ? (
                        <>
                          {this.state.cardMap.map((item, index) => {
                            return (
                              <div className="show-details" key={index}>
                                <Form.Check
                                  className="showdetails-radio"
                                  name="carddetails"
                                  type="radio"
                                  value={item.cardId}
                                  defaultChecked={
                                    item.isDefaultCard === 1 ? true : false
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      cardId: e.target.value,
                                    });
                                  }}
                                />
                                <MasterCard className="card-imgs" />
                                <p className="cardnumber">{item.cardLast4}</p>

                                <div className="select-cardtype">
                                  <Dropdown id={`cardimg-dropdown-${index}`}>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img
                                        src={require("../../assets/images/card-type.png")}
                                        className="setcardtypeimg"
                                        alt="cardType"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu id="web-dropdown">
                                      <p
                                        onClick={this.deleteCard.bind(
                                          this,
                                          item.cardId
                                        )}
                                      >
                                        Delete
                                      </p>
                                      <p
                                        onClick={this.setDefaultCard.bind(
                                          this,
                                          item.cardId
                                        )}
                                      >
                                        Set as a Default
                                      </p>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <ContextAwareAccordionToggle eventKey="1">
                    <Card.Header>Pay Without Saving Your Card</Card.Header>
                  </ContextAwareAccordionToggle>
                  <Accordion.Collapse
                    eventKey="1"
                    style={{
                      borderLeft: "#000000 2px solid",
                      borderRight: "#000000 2px solid",
                      borderBottom: "#000000 2px solid",
                    }}
                  >
                    <Card.Body>Hello! I am another body</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <h3 className="card-text">Select Your Card</h3>

              {this.state.cardMap != null && this.state.cardMap.length > 0 ? (
                <>
                  {this.state.cardMap.map((item, index) => {
                    return (
                      <div className="show-details" key={index}>
                        <Form.Check
                          className="showdetails-radio"
                          name="carddetails"
                          type="radio"
                          value={item.cardId}
                          defaultChecked={
                            item.isDefaultCard === 1 ? true : false
                          }
                          onChange={(e) => {
                            this.setState({
                              cardId: e.target.value,
                            });
                          }}
                        />
                        <MasterCard className="card-imgs" />
                        <p className="cardnumber">{item.cardLast4}</p>

                        <div className="select-cardtype">
                          <Dropdown id={`cardimg-dropdown-${index}`}>
                            <Dropdown.Toggle id="dropdown-basic">
                              <img
                                src={require("../../assets/images/card-type.png")}
                                className="setcardtypeimg"
                                alt="cardType"
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu id="web-dropdown">
                              <p
                                onClick={this.deleteCard.bind(
                                  this,
                                  item.cardId
                                )}
                              >
                                Delete
                              </p>
                              <p
                                onClick={this.setDefaultCard.bind(
                                  this,
                                  item.cardId
                                )}
                              >
                                Set as a Default
                              </p>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}

              <h3 className="card-text">Add New Card</h3>

              <div className="paymentinfo-form">
                <Form.Group>
                  <label className="label">Card Number</label>

                  <InputMask
                    type="text"
                    name="cardNumber"
                    className="mb-0 create-input"
                    placeholder="Enter card number"
                    mask="9999 9999 9999 9999"
                    maskChar={null}
                    value={this.state.cardNumber}
                    onChange={this.handleCardNumber}
                    required
                  />
                </Form.Group>
                <div className="payment-expiry">
                  <Form.Group className="col-6 pay-expiry">
                    <label className="label">EXPIRATION</label>

                    <InputMask
                      type="text"
                      name="cardExpiration"
                      className="mb-0 create-input"
                      placeholder="MM / YY"
                      mask="99/99"
                      maskChar={null}
                      value={this.state.cardExpiration}
                      onChange={this.handleCardExpiration}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="col-6 pay-cvc">
                    <label className="label">CVC</label>

                    <InputMask
                      type="text"
                      name="cardCVV"
                      id="create-cvv"
                      className="mb-0 create-input"
                      placeholder="Enter CVV"
                      mask="9999"
                      maskChar={null}
                      value={this.state.cardCVC}
                      onChange={this.handleCardCVC}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="save-btn">
                  <button
                    className="save"
                    type="submit"
                    onClick={() => this.addCard()}
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className="paymentinfo-form">
                <div className="save-btn">
                  <button
                    className="save"
                    type="submit"
                    onClick={() => this.makePayment()}
                  >
                    Make 1$ payment
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCard);
