import { takeLeading, put, all, call } from "redux-saga/effects";
import SignUpActionTypes from "./signUp.types";
import {
  userSignupSuccess,
  userSignupFailure,
  merchantSignupFailure,
  merchantSignupSuccess,
} from "./signUp.actions";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { log } from "../../helpers/core.helper";

/** performs user signup */
export function* signupUser({ payload }) {
  try {
    const signUpData = {
      email: payload.email,
      name: payload.name,
      password: payload.password,
      confirmPassword: payload.confirmPassword,
      phone: payload.phone,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      ipaddress: payload.ipaddress ?? "",
      termAgreementIds: payload.termAgreementIds ?? []
    };
    const res = yield UserService.signUp(signUpData);
    if (res.error != null) {
      yield put(
        userSignupFailure({
          signUpSuccess: false,
          loading: false,
          err: res.error.response.data.message,
        })
      );
    } else {
      if (res["status"]) {
        // const data = res["data"];
        // localStorage.setItem("employeeId", data.employeeId);
        // localStorage.setItem("employeeEmail", data.emailAddress);
        // localStorage.setItem("employeeData", JSON.stringify(data));
        // localStorage.setItem("employeeName", data.employee);
        // yield put(setEmployeeData({ employeeData: data }));
        yield put(userSignupSuccess({ signUpSuccess: true, loading: true }));
      } else {
        yield put(
          userSignupFailure({
            signUpSuccess: false,
            loading: false,
            err: res["message"],
          })
        );
      }
    }
  } catch (err) {
    yield put(
      userSignupFailure({ signUpSuccess: false, loading: false, err: err })
    );
    log("signUp.sagas", "signupUser", err);
  }
}

/** performs merchant signup */
export function* signupMerchant({ payload }) {
  try {
    const signUpData = {
      appId: AppConfig.API.APP_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      sphereTypeUrl: AppConfig.API.SPHERE_TYPE_URL,
      name: payload.name,
      phone: payload.phone,
      email: payload.email,
      password: payload.password,
      confirmPassword: payload.confirmPassword,
      //   verificationId: "Number",
      //   otp: "Number",
    };

    const res = yield UserService.merchantRegistration(signUpData);
    if (res.error != null) {
      yield put(
        merchantSignupFailure({
          merchantSignUpSuccess: false,
          loading: false,
          err: res.error.response.data.message,
        })
      );
    } else {
      if (res["status"]) {
        // const data = res["data"];
        // localStorage.setItem("merchantEmployeeId", data.employeeId);
        // localStorage.setItem("merchantEmployeeEmail", data.emailAddress);
        // localStorage.setItem("merchantEmployeeData", JSON.stringify(data));
        // localStorage.setItem("merchantEmployeeName", data.employee);
        yield put(
          merchantSignupSuccess({ merchantSignUpSuccess: true, loading: true })
        );
      } else {
        yield put(
          merchantSignupFailure({
            merchantSignUpSuccess: false,
            loading: false,
            err: res["message"],
          })
        );
      }
    }
  } catch (err) {
    yield put(
      merchantSignupFailure({
        merchantSignUpSuccess: false,
        loading: false,
        err: err,
      })
    );
    log("signUp.sagas", "signupMerchant", err);
  }
}

/** Saga for user signup */
export function* userSignup() {
  yield takeLeading(SignUpActionTypes.SIGNUP_START, signupUser);
}

/** Saga for merchant signup */
export function* merchantSignup() {
  yield takeLeading(SignUpActionTypes.MERCHANT_SIGNUP_START, signupMerchant);
}

/**
 * Initialise all local sagas
 */
export function* signUpSagas() {
  yield all([call(userSignup), call(merchantSignup)]);
}
