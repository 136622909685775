import { Component } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as Closeicon } from "../../assets/images/close_icon_black.svg";
import { ReactComponent as HandIcon } from "../../assets/images/Portal/Hand.svg";
import { Form } from "react-bootstrap";
import UserService from "../../services/user/user.service";
import AppConfig from "../../config/app.config";
import { NotificationManager } from "react-notifications";
import validator from "validator";
import Loader from "../../components/loader/loader";

const removeExtraSpace = (s) => s.trim().replace(/\s/g, "");
var pattern = new RegExp(/^[0-9\b]+$/);

class ContactUsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      employeeData: null,
      subjects: null,

      name: "",
      email: "",
      title: "",
      subjectId: -1,
      message: "",
      number1: 6,
      number2: 4,
      totalNumber: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSubjects();
    const min1 = 1;
    const max1 = 5;
    const min2 = 6;
    const max2 = 10;
    var number1 = Math.floor(Math.random() * (max1 - min1 + 1)) + min1;
    var number2 = Math.floor(Math.random() * (max2 - min2 + 1)) + min2;

    this.setState({ number1: number1, number2: number2 });
  }

  getSubjects = async () => {
    try {
      const payload = {
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        sphereUrl: AppConfig.API.SPHERE_TYPE_URL,
      };
      const res = await UserService.getContactSubjects(payload);
      if (res.error != null) {
        this.setState({ isLoading: 0 });
      } else {
        if (res["status"]) {
          const data = res["data"];
          if (data != null && data.length > 0) {
            this.setState({ subjects: res["data"], isLoading: 0 });
          } else {
            this.setState({ isLoading: 0 });
          }
        } else {
          this.setState({ isLoading: 0 });
        }
      }
    } catch (err) {
      this.setState({ isLoading: 0 });
    }
  };

  handleName = (event) => {
    this.setState({ name: event.target.value });
  };
  handleEmail = (event) => {
    this.setState({ email: removeExtraSpace(event.target.value) });
  };

  handleTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  handleMessage = (event) => {
    this.setState({ message: event.target.value });
  };

  handleTotalNumber = (event) => {
    if (pattern.test(event.target.value)) {
      this.setState({ totalNumber: parseInt(event.target.value) });
    }
    // if (event.target.value != null && event.target.value !== "") {
    //   console.log(event.target.value);
    // } else {
    //   this.setState({ totalNumber: null });
    // }
  };

  saveContactUs = async () => {
    if (this.state.name.trim() === "") {
      NotificationManager.warning("Please Enter Name.", "", 2000);
    } else if (this.state.name.length < 3) {
      NotificationManager.warning(
        "Name should be atleast 3 letters long.",
        "",
        2000
      );
    } else if (this.state.title === "") {
      NotificationManager.warning("Please Enter Title.", "", 2000);
    } else if (this.state.email.trim() === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else if (this.state.subjectId === -1) {
      NotificationManager.warning("Please Select Subject.", "", 2000);
    } else if (this.state.message.trim() === "") {
      NotificationManager.warning("Please Enter Message.", "", 2000);
    } else if (
      this.state.number1 + this.state.number2 !==
      this.state.totalNumber
    ) {
      NotificationManager.warning("Please Enter valid number sum.", "", 2000);
    } else {
      this.setState({ isLoading: 1 });
      try {
        const payload = {
          subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
          verticalId: AppConfig.API.VERTICAL_ID,
          appId: AppConfig.API.APP_ID,
          subjectId: this.state.subjectId,
          name: this.state.name,
          email: this.state.email,
          title: this.state.title,
          description: this.state.message,
          loginEmployeeId: this.props.employeeData?.employeeId,
        };
        console.log(payload);

        const res = await UserService.saveContactUs(payload);
        if (res.error != null) {
          NotificationManager.error(
            res.error?.response?.data?.message ?? "Could not save contact us.",
            "",
            3000
          );
          this.setState({ isLoading: 0 });
        } else {
          if (res["status"]) {
            NotificationManager.success("Contact Us Saved.", "", 3000);
            this.setState({
              name: "",
              email: "",
              subjectId: "",
              message: "",
              isLoading: 0,
              isSuccess: true,
            });
            this.props.onSaved();
          } else {
            NotificationManager.error("Could not save contact us.", "", 3000);
            this.setState({ isLoading: 0 });
          }
        }
      } catch (err) {
        NotificationManager.error("Could not save contact us.", "", 3000);
        this.setState({ isLoading: 0 });
      }
    }
  };

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="contact-us-modal"
      >
        {this.state.isLoading === 1 ? <Loader /> : ""}
        <div>
          <div className="portals-contactus-headertext">
            <h5
              style={{
                fontFamily: "Avenir",
                fontWeight: "300",
                fontSize: "24px",
                letterSpacing: "0.43px",
                color: "#000",
                transform: "uppercase",
                marginLeft: "20px",
              }}
            >
              CONTACT US
            </h5>
            <Closeicon
              className="close-icon"
              onClick={() => this.props.onHide()}
            />
          </div>

          <div className="order-confiramtion-modal">
            <div className="contact-usform-modal">
              <input
                type="text"
                placeholder="Name"
                className="contact-field"
                value={this.state.name}
                onChange={this.handleName}
              />
              <input
                type="text"
                placeholder="Title"
                className="contact-field"
                value={this.state.title}
                onChange={this.handleTitle}
              />
              <input
                type="email"
                placeholder="Email"
                className="contact-field"
                value={this.state.email}
                onChange={this.handleEmail}
                onKeyDown={(e) => {
                  if (e.key === " ") {
                    e.preventDefault();
                  }
                }}
              />
              <Form.Control
                aria-label="Default select example"
                as={"select"}
                className="contact-field"
                id="contact-select-field"
                value={this.state.subjectId}
                onChange={(e) =>
                  this.setState({ subjectId: parseInt(e.target.value) })
                }
              >
                <option value={-1}>Subject</option>
                {this.state.subjects !== null &&
                  this.state.subjects.length > 0 &&
                  this.state.subjects.map((res, index) => {
                    return (
                      <option key={index} value={res.key}>
                        {res.value}
                      </option>
                    );
                  })}
                {/* <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option> */}
              </Form.Control>
              <textarea
                placeholder="Message"
                className="contact-message"
                value={this.state.message}
                onChange={this.handleMessage}
              />
              <div className="contact-send-btn">
                <div className="contact-submit-calculate">
                  <p>
                    {this.state.number1} + {this.state.number2} =
                  </p>
                  <input
                    type="text"
                    className="number-addfield"
                    value={this.state.totalNumber}
                    onChange={this.handleTotalNumber}
                  />
                </div>
                <button
                  type="submit"
                  className="send-btn1"
                  onClick={this.saveContactUs}
                >
                  SEND
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ContactUsModal;
