import React from "react";

const settings = {
  velocity_x: 1,
  velocity_y: 0.4,
  scale_y: 3.9,
  y_offsetSeed: 0,
  y_center: 250,
  maxradius: 7,
  decay: true,
  colorPct: 10,
  growth: true,
  irregColors: true,
  particles: 489,
  freqSeed: 2.1,
  amplitudeSeed: 158,
  velocity_xSeed: 2,
  velocity_ySeed: 2,
  opacMax: 0.6,
  opacMin: 0.01,
  opacSeed: 90,
};

const star = function () {
  this.offset = 0;
  this.x = 0;
  this.y = 0;
  this.params = {
    velocity_x: 1,
    velocity_y: 1,
    opacicty: 1,
    lifetime: 3,
    x_offset: 0,
    y_offset: 0,
    radius: 0,
    decay: 0,
    growth: 0,
    amplitude: 100,
    phaseshift: 360,
    freq: 0.05,
    color: "255,255,255",
  };
};

star.prototype.init = function () {
  //Randomize
  this.params.phaseshift = Math.random() * 0.01;
  this.params.amplitude = Math.ceil(Math.random() * settings.amplitudeSeed);
  this.params.freq = Math.random() * settings.freqSeed;
  this.params.x_offset = Math.ceil(Math.random() * 1000);
  this.params.y_offset =
    settings.y_center + Math.ceil(Math.random() * settings.y_offsetSeed);
  this.params.lifetime = Math.ceil(Math.random() * 3);
  this.params.velocity_x = Math.random() * settings.velocity_xSeed;
  this.params.velocity_y = Math.random() * settings.velocity_ySeed;
  this.params.radius = Math.random() * settings.maxradius;
  this.params.opacity = Math.round(Math.random() * settings.opacSeed, 2) / 100;
  if (settings.decay) {
    this.params.decay = Math.round(Math.random() * 1.5) * Math.random() * 0.01;
  }
  if (settings.growth) {
    this.params.growth = Math.round(Math.random() * 1.2) * Math.random() * 0.01;
  }

  if (settings.irregColors) {
    switch (Math.ceil(Math.random() * settings.colorPct)) {
      case 1:
        this.params.color = "255,0,0";
        break;
      case 2:
        this.params.color = "0,255,255";
        break;
      case 3:
        this.params.color = "0,255,200";
        break;
      case 4:
        this.params.color = "255,0,255";
        break;
      default:
        this.params.color = "255,255,255";
        break;
    }
  }
  this.x = this.params.x_offset;
};

star.prototype.draw = function (ctx, new_x) {
  this.offset = this.offset + 1;
  var t = this.params.x_offset + this.offset;

  if (this.params.opacity > settings.opacMax) {
    this.params.decay *= -1;
    this.params.lifetime--;
  } else if (this.params.opacity <= settings.opacMin) {
    this.params.lifetime--;
    this.params.decay *= -1;
    this.params.opacity = 0;
  }
  if (this.params.radius > settings.maxradius) {
    this.params.growth *= -1;
  } else if (this.params.radius <= 0.2) {
    this.params.growth *= -1;
    this.params.radius = 0.2;
  }
  this.params.radius += 2 * this.params.growth;

  this.params.opacity += 2 * this.params.decay;

  this.y =
    this.params.y_offset +
    (this.params.amplitude / 4) *
      Math.sin((2 + t * settings.velocity_y * 0.03) * this.params.freq) *
      this.params.velocity_y *
      settings.scale_y;
  this.x += 1 * this.params.velocity_x * settings.velocity_x;
  ctx.beginPath();
  ctx.fillStyle =
    "rgba(" +
    this.params.color +
    "," +
    Math.round(this.params.opacity * 100) / 100 +
    ")";
  ctx.arc(this.x, this.y, this.params.radius, 0, Math.PI * 2, false);
  ctx.fill();
};

const stars = [];

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
    };

    window.requestAnimFrame = (function () {
      return function (callback) {
        const animFrame =
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame;

        window.setTimeout(() => {
          if (animFrame != null) {
            animFrame(callback);
          } else {
            callback();
          }
        }, 1000 / 60);
      };
    })();
  }

  componentDidMount() {
    this.init();
  }

  init = function () {
    for (var i = 0; i < settings.particles; i++) {
      stars[i] = new star();
      stars[i].init();
    }
  };

  setUpCanvas = () => {
    var ctx = document.getElementById("myCanvas").getContext("2d");
    var offset = 0;
    (function animloop() {
      window.requestAnimFrame(animloop);
      ctx.fillStyle = "rgba(0,0,0,1)";
      ctx.fillRect(0, 0, 1000, 1000);
      for (var i = 0; i < settings.particles; i++) {
        stars[i]?.draw(ctx);
        ctx.beginPath();
        ctx.fill();
        if (
          stars[i]?.x > 998 ||
          (stars[i]?.params.lifetime < 0 && stars[i]?.params.opacity <= 0) ||
          stars[i]?.y < 0 - settings.maxradius ||
          stars[i]?.x < 0 - settings.maxradius ||
          stars[i]?.y > 500 + settings.maxradius
        ) {
          stars[i] = new star();
          stars[i].init();
          stars[i].params.x_offset = -1 * offset;
          stars[i].x -= Math.random() * 200;
          stars[i].params.opacity = 0;
        }
      }
    })();
  };

  render() {
    return (
      <canvas
        ref={(ref) => {
          this.setUpCanvas();
        }}
        id="myCanvas"
        height="500"
        width="1000"
      />
    );
  }
}

export default Canvas;
