import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VideoModal from "../../components/model/video-modal";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClose = () => {
    this.setState({ isShowModel: false, modelUrl: "" });
  };

  handleWorldEducationShow = (event) => {
    event.target.blur();
    this.setState({
      isShowModel: true,
      modelUrl:
        "https://ciright-public.s3.amazonaws.com/wei_draft_07.2+(1080p)+(1).mp4",
    });
  };

  render() {
    return (
      <div className="portal-about">
        <VideoModal show={this.state.isShowModel} onHide={this.handleClose} />

        <div className="portalApp-about">
          <img
            src={require("../../assets/images/About-image (1).png")}
            className="About-image"
            alt="About"
          ></img>
          <p>
            <span>Our Mission</span> <br></br>
            <br></br>
            Portal feature the most influential artists across various genres of
            music. The fully immersive experiences connect artists and fans -
            wherever they are in the world. Portal is a biblical name, meaning
            movement and belonging to all. Music has a history of effecting
            change. The Portal movement will respect artists and their fans,
            while improving the end-to-end live music experience. Let music
            light the way.
          </p>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
