const URL = "https://www.myciright.com/Ciright";

const AppConfigMaster = {
  API: {
    ENDPOINT: URL + "/api",
    USER_PROFILE:
      URL +
      "/ajaxCall-photo.htm?flag=employeePhoto&compress=0&colorCode=veuit&id=",
    MANU_IMAGE:
      URL + "/ajaxCall-photo.htm?flag=veuitNetworkManufactLogo&compress=0&id=",

    SUBSCRIPTION_ID: 8914081,
    VERTICAL_ID: 2803,
    APP_ID: 2836,
    SPHERE_TYPE_URL: "todays-portal.htm",
    SPHERE_TYPE_ID: 2,
    BASE_PATH: null,
    CIRIGHT_WORLD_VERTICAL_ID: 18,
    SPONSOR_SPHERE_TYPE_URL: "zaza-sponsership",
    APP_EVENT_ID: 2105687,
    APP_ZIPCODE: "08401",
    PAYMENT_MODE: 1,
    APP_FEST_TICKET_SALE_PAYMENT_INITIATE_PHASE: 1013471,
    APP_FEST_TICKET_SALE_PAYMENT_PROCESSED_PHASE: 1013469,
    APP_FEST_TICKET_SALE_PAYMENT_FAILED_PHASE: 1013470,
    EVENT_ID: 2783018,
    EVENT_NAME: "Cian Ducrot - Live from O’Connell Street – Dublin Ireland",
    EVENT_HOST_NAME:"Cian Ducrot",
    EVENT_PLACE:"Dublin Ireland",
    IS_ANNOUNCEMENT: 2,
    LIVE_STREAM_LINK: "https://portals.ciright.com/ps/",
    LIVE_STREAM_BUCKET: "CirightLive1",
    INSTA_LINK: "https://www.instagram.com/ciright_inc/",
    TIKTOK_LINK: "",
    LINKDIN_LINK:
      "https://www.linkedin.com/in/ciright-admin-906b8a238?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    X_LINK: "",
    IS_VIEW_BANIIFIT:1,
  },
};

export default AppConfigMaster;
