import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import validator from "validator";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { selectemployeeData } from "../../store/home/home.selectors";
import AppConfig from "../../config/app.config";
import Loader from "../../components/loader/loader";
import UserService from "../../services/user/user.service";

const mapStateToProps = (state) => {
  return {
    employeeData: selectemployeeData(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

const removeExtraSpace = (s) => s.trim().replace(/\s/g, "");

export class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 0,
      email: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.employeeData?.employeeId != null) {
      this.props.history.push("/");
    } else {
      this.setState({
        email: "",
      });
    }
  }

  handleEmail = (event) => {
    this.setState({ email: removeExtraSpace(event.target.value) });
  };

  sendResetLink = () => {
    if (this.state.email.trim() === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000);
    } else if (!validator.isEmail(this.state.email)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000);
    } else {
      const payload = {
        emailAddress: this.state.email,
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        appId: AppConfig.API.APP_ID,
      };
      this.setState({ isLoading: 1 });
      UserService.sendResetPasswordLink(payload)
        .then((res) => {
          if (res.error != null) {
            this.setState({ isLoading: 0, email: "" });
            NotificationManager.error(
              res.error.response.data.message,
              "",
              2000
            );
          } else {
            if (res["status"]) {
              if (res["message"] === "success") {
                NotificationManager.success(
                  "A link has been sent to your Email.",
                  "",
                  2000
                );
                this.timeoutHandle = setTimeout(() => {
                  this.setState({ isLoading: 0, email: "" });
                  this.props.history.push("/authentication/my-account");
                }, 3000);
              } else {
                this.setState({ isLoading: 0, email: "" });
                NotificationManager.error(res["message"], "", 2000);
              }
            } else {
              this.setState({ isLoading: 0, email: "" });
              NotificationManager.error(res["message"], "", 2000);
            }
          }
        })
        .catch((err) => {
          this.setState({ isLoading: 0 });
          NotificationManager.error(err, "", 2000);
        });
    }
  };

  render() {
    return (
      <>
        {this.state.isLoading === 1 && <Loader />}
        <div className="checkout-white-bg">
          <div className="portal-checkout">
            <h4>Forgot Password</h4>
            <div className="create-return" id="forgotpassword">
              <div className="create-return-right">
                <div className="create-return-form">
                  <Form.Group>
                    <label className="label">Email</label>
                    <Form.Control
                      type="email"
                      className="mb-0 create-signin"
                      id="txtEmail"
                      // placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleEmail}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                  <button
                    className="Sign-In"
                    type="submit"
                    onClick={this.sendResetLink}
                  >
                    Send Reset Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-portalApp-checkout">
          <div className="portalApp-footer-img">
            <p>© 2024 Ciright Live. All rights reserved.</p>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
