import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactComponent as Location1 } from "../../assets/images/map.svg";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export class Location extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="portal-location">
        <Link
          target="_blank"
          to={{ pathname: "https://goo.gl/maps/85XA4WzBDSsGtEaq8" }}
        >
          <Location1 className="location-image" />
        </Link>
        <div className="portal-loc-address">
          <p>
            <span>Location:</span> Bader Field 545 Albany Ave, Atlantic City, NJ
            08401
          </p>
        </div>
        <Link
          target="_blank"
          to={{ pathname: "https://goo.gl/maps/85XA4WzBDSsGtEaq8" }}
        >
          <img
            src={require("../../assets/images/location-icon.png")}
            className="location-icon"
            alt="location-icon"
          ></img>
        </Link>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Location);
